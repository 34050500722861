  
.filterCard {
  padding: 1rem;
  background-color: rgb(31, 31, 31);
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;

  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.filter {
  color: white;
  padding: 0 1rem;
}

.filter__control {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.filter label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.filter select {
  font: inherit;
  padding: 0.5rem 3rem;
  font-weight: bold;
  border-radius: 6px;
  /* background-color: blueviolet; */
  background-color: #01405c;
  display: flex;
  width:50vw;
}

