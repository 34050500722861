:root {
  /* --primary-color: #26a6fc; */
  --primary-color: #60aee2;
  --secondary-color: #093958;
  --go-color: #42dcb6;

  --box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  --box-shadow-hover: 0 20px 40px rgba(0, 0, 0, 0.3);
  --transition-duration: 0.5s;
  --transition: 0.6s cubic-bezier(0.83, 0, 0.17, 1);

  --master-border-radius: 10px;

  --precision: #42dcb6;
  --finesse: #3cb4d8;
  --speed: #df3b35;
  --perception: #4a2f90;
  --spatial: #df9035;
  --shading: #185f99;
}

body {
  background-image: url("../images/body.png");
  font-family: Roboto, sans-serif;
  color: #eee;
  margin: 0;
  padding: 0;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

.hiddenImage {
  left: -9999%;
  position: absolute;
}

.loginBody {
  background-image: url("../images/indexbg.jpg");
  height: 100vh;
}

.login-logo {
  width: 800px;
  height: 300px;
  margin: 50px auto 0 auto;
}

.login-logo.a {
  fill: none;
}

#sketchCanvas {
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-image: url('');*/
  width: 100vw;
  height: 100vh;
  cursor: crosshair;
  position: fixed;
  /* left: -999%; */
  /* background-image: url(../images/contour1.png);
  background-size: 50vw 100vh;
  background-repeat: no-repeat; */
}

#sketchCanvasSFT {
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-image: url('');*/
  width: 100vw;
  height: 100vh;
  cursor: crosshair;
  position: fixed;
  /* left: -999%; */
  background-image: url("../images/challenges/cameraIsometric.png");
  background-size: 15vw, 15vh;
  background-position: 200% 200%;
}

#sketchCanvasSFT.active {
  z-index: 1;
  top: 0;
  left: 0;
}

#sketchCanvasSFT.active.camera {
  background-size: 15vw, 15vh;
  background-position: 95% 5%;
}

#sketchCanvas.active {
  z-index: 1;
  top: 0;
  left: 0;
}

#sketchCanvas.active.lesson {
  z-index: 1;
  top: 0;
  left: 30%;
}

#sketchCanvas.sandala {
  z-index: 1;
  position: static;
  top: 0px;
  left: 0px;
  width: 90vh;
  height: 90vh;
  margin: 3% auto 0 auto;
  border-radius: 45vh;
  display: block;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#sketchCanvas.cameraIsometric {
  background-image: url("../images/challenges/cameraIsometric.png");
  background-size: 25vw, 25vh;
  background-position: 85% 20%;
}

#sketchCanvas.SFTCamera {
  background-image: url("../images/challenges/cameraIsometric.png");
  background-size: 15vw, 15vh;
  background-position: 95% 5%;
}

a {
  color: #eee;
}

.row .col {
  padding: 0 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #eee;
  font-weight: 100;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.3em;
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  width: 0px;
}

/* Clear Spaces */
.clear0 {
  width: 100%;
  clear: both;
}

.clear20 {
  min-height: 20px;
  width: 100%;
  clear: both;
}

.clear40 {
  min-height: 40px;
  width: 100%;
  clear: both;
}

.clear60 {
  min-height: 60px;
  width: 100%;
  clear: both;
}

.clear80 {
  min-height: 80px;
  width: 100%;
  clear: both;
}

.clear100 {
  min-height: 100px;
  width: 100%;
  clear: both;
}

.sidenav-overlay {
  background-color: rgba(9, 57, 88, 0.95);
  opacity: 0.9;
}

/* Login */

.loginLogo {
  width: 800px;
  height: 200px;
  margin: 0 auto 0 auto;
}

.loginBox {
  max-width: 300px;
  margin: 60px auto 0 auto;
  border-radius: 5px;
}

.login-button {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 1.1em;
}

.register-button {
  width: 260px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 1.1em;
  position: relative;
  margin: 0 auto;
  display: block;
}

.reset-button {
  background-color: rgba(0, 0, 0, 0.3);
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  margin-bottom: 10px;
  cursor: hand;
}

.login-button:hover,
.reset-button:hover,
.register-button:hover {
  opacity: 0.6;
  transition-duration: var(--transition-duration);
}

#registerOverlay {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  opacity: 0;
  position: fixed;
  z-index: -1;
  transition-duration: var(--transition-duration);
  overflow-y: hidden;
}

#registerOverlay.active {
  opacity: 0.95;
  overflow-y: auto;
  z-index: 1000;
  transition-duration: var(--transition-duration);
}

/* https://jacobruiz.com/blog/2021/2/11/how-to-transition-placeholder-text-into-a-label-in-react-floating-label-inputs */
.input-field {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-field label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-field .filled {
  transform: translate(0, -12px) scale(0.5);
}

.input-field:focus-within label {
  transform: translate(0, -12px) scale(0.5);
  color: #0a53e4;
}

/* - End code reference - */

#forgotPasswordOverlay {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  opacity: 0;
  position: fixed;
  z-index: -1;
  transition-duration: var(--transition-duration);
  overflow-y: hidden;
}

#forgotPasswordOverlay.active {
  opacity: 0.95;
  overflow-y: auto;
  z-index: 1000;
  transition-duration: var(--transition-duration);
}

/* Sidebar */

.sidenav {
  background-color: var(--primary-color);
  color: rgb(238, 238, 238);
}

.sidenav li > a {
  color: #eee;
  font-size: 24px;
  height: 60px;
  padding-top: 5px;
  font-weight: 300;
}

.sidenav li > a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  transition-duration: var(--transition-duration);
}

.sidenav .user-view {
  height: 80px;
  margin: 10px 20px;
}

#sidenav-overlay {
  background-color: #093958;
  opacity: 0.7;
}

.sidenav li > a > i,
.sidenav li > a > i.material-icons {
  margin: 0 20px 0 0;
}

/* Header */

.toggle-menu {
  height: 4em;
  width: 4em;
  background: var(--primary-color) url("../images/emblem.png") no-repeat;
  background-size: 80% 80%;
  background-position: center center;
  border-radius: 2em;
  display: inline-block;
  top: 1.5em;
  left: 1.5em;
  position: fixed;
  box-shadow: var(--box-shadow);
  z-index: 3;
}

#pageTitle {
  height: 4em;
  min-width: 4em;
  line-height: 4em;
  position: fixed;
  left: 5em;
  padding: 0 2em;
  color: #fff;
}

#sketchTitle {
  position: fixed;
  top: 0.8em;
  left: 1em;
  min-width: 300px;
  padding: 0 2em;
  z-index: 2;
  display: none;
  color: var(--primary-color);
  font-size: 2.4em;
  font-weight: 100;
  cursor: pointer;
}

#sketchTitle.active {
  display: block;
}

#sketchTokens {
  height: 4em;
  width: 4em;
  background: url("../images/icons/sketchtoken.svg") no-repeat;
  background-size: cover;
  position: fixed;
  top: 1.5em;
  border-radius: 2em;
  right: 12em;
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

#sketchTokens:hover {
  opacity: 0.8;
  transition-duration: var(--transition-duration);
}

#sketchTokenBalance {
  height: 2em;
  width: 2em;
  background-color: #9e7c1d;
  border-radius: 1em;
  line-height: 2em;
  text-align: center;
  margin: 2em 0 0 2em;
}

#profile {
  height: 4em;
  min-width: 7em;
  border-radius: 2em;
  background-color: var(--secondary-color);
  top: 1.5em;
  right: 1.5em;
  position: fixed;
  box-shadow: var(--box-shadow);
  z-index: 3;
  line-height: 4em;
}

#left-vs-right {
  width: 200px;
  height: 100px;
  line-height: 30px;
  margin-bottom: 20px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

#lefty {
  background: url("../images/lefty.svg") no-repeat bottom left;
  background-size: 70px 70px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  float: left;
  cursor: pointer;
}

#righty {
  background: url("../images/righty.svg") no-repeat bottom right;
  background-size: 70px 70px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  float: right;
  cursor: pointer;
}

#righty.active {
  background-color: var(--primary-color);
}

#lefty.active {
  background-color: var(--primary-color);
}

#righty:hover,
#lefty:hover {
  background-color: var(--primary-color);
  opacity: 0.7;
  transition-duration: 1s;
}

#avatar {
  height: 4em;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url("../images/avatars/leo.svg");
  border-radius: 2em;
  display: inline-block;
}

#avatarLevel {
  height: 2em;
  width: 2em;
  background-color: var(--secondary-color);
  border-radius: 1em;
  line-height: 2em;
  text-align: center;
  margin: 2em 0 0 2em;
}

#name {
  color: #eee;
  font-size: 1em;
  padding: 0 2em 0 1em;
  display: inline-block;
  line-height: 4em;
  float: right;
}

/* Profile */

#profile:hover,
.toggle-menu:hover {
  opacity: 0.8;
  transition-duration: var(--transition-duration);
  cursor: pointer;
}

#profileOverlay {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
  transition: var(--transition);
  overflow-y: hidden;
}

#profileOverlay.active {
  opacity: 0.95;
  overflow-y: auto;
  z-index: 10;
  transition: var(--transition);
}

#profileOverlay .container {
  width: 80%;
}

#exitProfile {
  height: 4em;
  width: 4em;
  background-image: url("../images/icons/exit.svg");
  background-color: rgba(0, 0, 0, 0.2);
  top: 1.3em;
  right: 1.3em;
  border-radius: 2em;
  position: fixed;
  cursor: pointer;
}

.profileLeft {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a1000000',GradientType=1 ); /* IE6-9 */
  height: 100vh;
  width: 25%;
  position: fixed;
  left: 0;
  top: 0;
}

#profileAvatar {
  height: 200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url("../images/avatars/leo.svg");
  border-radius: 100px;
  margin: 30px auto 0 auto;
  padding: 160px 0 0 140px;
}

#profileAvatarLevel {
  height: 50px;
  min-width: 100px;
  background-color: #0a2b42;
  font-size: 1.5em;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
}

#profileName {
  text-align: center;
  margin-bottom: 60px;
}

#profileMenu li {
  text-align: center;
  border-right: 3px solid rgba(255, 255, 255, 0.1);
  margin: 0;
  padding: 0;
  line-height: 2.5em;
  font-size: 1.8em;
  font-weight: 100;
  opacity: 0.7;
  cursor: pointer;
}

#profileMenu li.active {
  border-right: 3px solid rgba(255, 255, 255, 1);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a1000000',GradientType=1 ); /* IE6-9 */
  opacity: 1;
}

#profileMenu li:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a1000000',GradientType=1 ); /* IE6-9 */
  opacity: 1;
}

.profileRight {
  width: 75%;
  float: right;
}

.profileSection {
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 10px 30px 20px 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--master-border-radius);
}

.profileSection h2 {
  margin: 5px 0 30px 0;
}

#stats .row {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--master-border-radius);
  padding: 0 20px;
}

.bar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  margin-top: 34px;
}

#levelProgress {
  background-color: #fff;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

#precisionProgress {
  background-color: #42dcb6;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

#finesseProgress {
  background-color: #3cb4d8;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

#speedProgress {
  background-color: #df3b35;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

#perceptionProgress {
  background-color: #4a2f90;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

#spatialProgress {
  background-color: #df9035;
  height: 20px;
  border-radius: 10px;
  width: 20%;
}

.precisionTitle {
  background: var(--precision) url("../images/icons/statPrecision.svg") left
    center no-repeat;
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.finesseTitle {
  background: var(--finesse) url("../images/icons/statFinesse.svg") left center
    no-repeat;
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.speedTitle {
  background: var(--speed) url("../images/icons/statSpeed.svg") left center
    no-repeat;
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.perceptionTitle {
  background: var(--perception) url("../images/icons/statPerception.svg") left
    center no-repeat;
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.spatialTitle {
  background: var(--spatial);
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.shadingTitle {
  background: var(--shading);
  padding-left: 40px;
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
}

.skillModule {
  margin-right: 10px;
  border-radius: 20px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  opacity: 0.1;
  font-weight: 300;
  margin-top: 30px;
}

.skillModule.active {
  opacity: 1;
}

/* Badges */

#badges {
}

#badges li {
  width: 100%;
  min-height: 100px;
  opacity: 0.2;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--master-border-radius);
  padding: 10px 10px;
}

#badges .row {
  margin-bottom: 0px;
}

#badges h3,
#badges h4 {
  line-height: 0.5em;
  margin-left: 20px;
}

#badges li.active {
  opacity: 1;
}

#lessons1 {
  background-image: url("../images/badges/lessons1.svg");
}

#lessons5 {
  background-image: url("../images/badges/lessons5.svg");
}

#lessons10 {
  background-image: url("../images/badges/lessons10.svg");
}

#challenges1 {
  background-image: url("../images/badges/challenges1.svg");
}

#challenges5 {
  background-image: url("../images/badges/challenges5.svg");
}

#challenges10 {
  background-image: url("../images/badges/challenges10.svg");
}

#highscore {
  background-image: url("../images/badges/highscore.svg");
}

#master {
  background-image: url("../images/badges/master.svg");
}

#daily3x {
  background-image: url("../images/badges/3xdaily.svg");
}

#daily7x {
  background-image: url("../images/badges/7xdaily.svg");
}

#daily30x {
  background-image: url("../images/badges/30xdaily.svg");
}

#inktober {
  background-image: url("../images/badges/inktober.svg");
}

#months3 {
  background-image: url("../images/badges/3months.svg");
}

#months6 {
  background-image: url("../images/badges/6months.svg");
}

#months12 {
  background-image: url("../images/badges/12months.svg");
}

#valentines {
  background-image: url("../images/badges/valentines.svg");
}

#independence {
  background-image: url("../images/badges/independenceday.svg");
}

/* Lessons */
.leader-line {
  z-index: -1;
}
.lesson {
  width: 100%;
  cursor: pointer;
}

.lessonIcon {
  background-color: #fff;
  /* height: 200px;
  width: 200px; */
  /* height: 18vw;
  width: 18vw; */
  min-height: 150px;
  min-width: 150px;
  margin: 0 auto;
  border-radius: 50%;
  /* border-radius: 10vw; */
  padding-top: 18vw;
  background-size: 70% 70%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: var(--box-shadow);
}

.lessonLocked {
  background: var(--secondary-color) url("../images/icons/locked.svg") !important;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 100px;
  padding-top: 160px;
  background-size: 70% 70%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: var(--box-shadow);
}

.lessonTitle {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  height: 5vh;
  line-height: 5vh;
  border-radius: 30px;
  padding: 0 20px;
  text-align: center;

  /* width: 150px; */
  width: fit-content;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
  align-self: center;
}

.lessonStatus {
  /* background-color: #093958; */
  color: #fff;
  font-size: 1em;
  font-weight: 200;
  height: 3vh;
  line-height: 3vh;
  border-radius: 30px;
  padding: 0 10px;
  text-align: center;
  /* width: 150px; */
  width: fit-content;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
}

.lessonNotification {
  color: #fff;
  font-size: 1em;
  height: 3vh;
  line-height: 3vh;
  font-weight: 200;
  text-align: center;
}

/* .lessons-main {
	width: 960px;
	margin: 0 auto;

} */

.lessons-nav {
  width: 25%;
  /* width: 280px; */
  background-color: #4993bc;
  float: left;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.3);
  margin-left: 10%;
}

.lessons-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lessons-nav li {
  line-height: 60px;
  height: 60px;
  padding-left: 60px;
  transition-duration: 0.5s;
  background-color: #4993bc;
  background-repeat: no-repeat;
}

.lessons-nav li:hover {
  background-color: #357ca3;
  transition-duration: 0.5s;
}

.lessons-nav li.active {
  background-image: url("../images/lessons/progress-active.svg");
  background-color: #357ca3;
  border-right: 5px solid #fff;
}

.lessons-nav li.inactive {
  background-image: url("../images/lessons/progress-none.svg");
}

.lessons-nav li.full {
  background-image: url("../images/lessons/progress-full.svg");
}

.lessons-left {
  background-color: rgba(0, 0, 0, 0.2);
  /* width: 600px; */
  width: 55%;
  min-height: 300px;
  padding: 5%;
  margin-right: 10%;
  margin-bottom: 10%;
  /* padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%; */
  float: left;
}

/* Quick fix to merge codebases */
.lessons-left-hidden {
  background-color: rgba(0, 0, 0, 0);
}

.lesson-overview-right {
  width: 600px;
  float: right;
  background-color: rgba(0, 0, 0, 0.2);
}

.lesson-overview-left {
  width: 400px;
  float: left;
  background-color: rgba(0, 0, 0, 0.1);
}

.lesson-overview-summary {
  margin-top: 70px;
  height: 83px;
  padding: 0 20px 0 20px;
}

.lessons-main {
  width: 960px;
  margin: 0 auto;
}

.gameTitle {
  background-color: #fc8626;
  color: #fff;
  font-size: 1.4em;
  font-weight: 200;
  height: 60px;
  border-radius: 30px;
  padding: 0 20px;
  text-align: center;
  line-height: 60px;
  width: 150px;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
}

.lessonStars {
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  width: 150px;
  margin: -10px auto 0 auto;
  background: var(--secondary-color) url("../images/icons/5star.svg") no-repeat
    center center;
  background-size: 80% 80%;
  box-shadow: var(--box-shadow);
}

.lessonScore {
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  width: 150px;
  margin: 0 auto 0 auto;
  font-size: 1.4em;
  font-weight: 100;
  background: var(--secondary-color);
  box-shadow: var(--box-shadow);
}

#lessonLines {
  background-image: url("../images/lessonIcons/lines.png");
}

#lessonSquares {
  background-image: url("../images/lessonIcons/squares.png");
}

#lessonCircles {
  background-image: url("../images/lessonIcons/circles.png");
}

#lessonSymmetry {
  background-image: url("../images/lessonIcons/symmetry.png");
}

#lessonArcs {
  background-image: url("../images/lessonIcons/arcs.png");
}

#lessonContours {
  background-image: url("../images/lessonIcons/lesson-contours.png");
}

#lessonPlanes {
  background-image: url("../images/lessonIcons/planes.png");
}

#lessonEllipses {
  background-image: url("../images/lessonIcons/ellipses.png");
}

#lessonCubes {
  background-image: url("../images/lessonIcons/cubes.png");
}

#lessonCylinders {
  background-image: url("../images/lessonIcons/cylinders.png");
}

#lessonCones {
  background-image: url("../images/lessonIcons/cones.png");
}

#lessonSpheres {
  background-image: url("../images/lessonIcons/spheres.png");
}

#lesson1ptPerspective {
  background-image: url("../images/lessonIcons/perspective.png");
}

#lesson2ptPerspective {
  background-image: url("../images/lessonIcons/perspective2.png");
}

#lessonAdditiveCombos {
  background-image: url("../images/lessonIcons/lessoncombos-add.png");
}

#gameZen {
  background-image: url("../images/lessonIcons/zensketch.jpg");
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

#main-progressbar-container {
  position: fixed;
  left: 3.3em;
  top: 8em;
  width: 3px;
  height: 50vh;
  background-color: rgba(255, 255, 255, 0.4);
  display: block;
  border-radius: 2px;
}

#main-progressbar {
  width: 3px;
  height: 0%;
  border-radius: 2px;
  background-color: var(--primary-color);
  -webkit-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;
}

/* Lesson Template */

#lessonInfo {
  opacity: 0;
}

#lessonInfo.active {
  opacity: 1;
  transition-duration: var(--transition-duration);
}

.lessonProgress {
  background-color: #ddd;
  height: 4px;
  border-radius: 2px;
  width: 60%;
  margin: 0 auto;
  position: fixed;
  top: 4em;
  left: 20%;
  z-index: 2;
}

#lessonProgressBar {
  background-color: var(--primary-color);
  border-radius: 2px;
  height: 4px;
  width: 0%;
  transition-duration: 1s;
}

#lessonProgressText {
  font-size: 20px;
  color: #ccc;
  position: fixed;
  top: 1.2em;
  left: 20%;
  text-transform: uppercase;
  z-index: 2;
}

#clearSketch {
  position: fixed;
  left: 45%;
  width: 10%;
  height: 3em;
  top: 6em;
  line-height: 3em;
  text-transform: uppercase;
  background-color: #ddd;
  color: #aaa;
  border-radius: 1.5em;
  text-align: center;
  cursor: pointer;
  display: none;
  z-index: 3;
}

#clearSketch:hover {
  opacity: 0.9;
  transition-duration: var(--transition-duration);
}

#help {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 4em;
  width: 4em;
  border-radius: 2em;
  background: var(--secondary-color) url("../images/icons/help.svg") no-repeat;
  background-size: 80% 80%;
  background-position: center center;
  box-shadow: var(--box-shadow);
  z-index: 2;
  cursor: pointer;
}

#notifications {
  width: 200px;
  min-height: 400px;
  right: 1.5em;
  top: 6em;
  position: fixed;
  z-index: 5;
}

#notifications ul {
  list-style-type: none;
  margin: 0;
  padding: 20px;
}

#notifications li {
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 100;
  width: 200px;
  box-shadow: var(--box-shadow);
}

.exp {
  background-color: rgba(0, 0, 0, 0.2);
}

.precision {
  background-color: var(--precision);
}

.smoothness,
.finesse {
  background-color: var(--finesse);
}

.speed {
  background-color: var(--speed);
}

.perception {
  background-color: var(--perception);
}

.spatial {
  background-color: var(--spatial);
}

.shading {
  background-color: var(--shading);
}

/* Challenges */
.card {
  margin-right: 20px;
}

.card:hover {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
  transition-duration: var(--transition-duration);
}

.card-content {
  background-color: var(--primary-color);
}

#challengecameraIsometric {
  background-image: url("../images/challenges/cameraIsometric.png");
}

#challengeapp {
  background-image: url("../images/challenges/app.png");
}

#challengechair {
  background-image: url("../images/challenges/chair.png");
}

/* Sketchpad */

#editSketchTitle {
  position: fixed;
  top: 1.5em;
  left: 7em;
  width: 400px;
  display: none;
  z-index: 2;
}

#editSketchTitle.active {
  display: block;
}

#editSketchTitle input {
  color: var(--primary-color);
  font-size: 2.4em;
  font-weight: 100;
  width: 300px;
}

#sketchpadTools {
  opacity: 0;
  z-index: 2;
}

#sketchpadTools.active {
  opacity: 1;
}

.tools {
  width: 50px;
  position: fixed;
  left: 1.8em;
  top: 8em;
  z-index: 2;
}

.tools ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tools li {
  display: block;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #ccc;
  box-shadow: var(--box-shadow);
}

.tools li.active {
  width: 55px;
  border-right: #4993bc 5px solid;
}

.tools li:hover {
  width: 60px;
  transition-duration: var(--transition-duration);
}

.tools li:first-child {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.tools li:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

#eraser {
  background: #eee url("../images/icons/eraser.png") no-repeat left;
}

#bluepencil {
  background: #eee url("../images/icons/bluepencil.png") no-repeat left;
}

#pencil {
  background: #eee url("../images/icons/pencil.png") no-repeat left;
}

#shapeListener {
  background: #eee url("../images/icons/pencil.png") no-repeat left;
}

#ballpoint {
  background: #eee url("../images/icons/ballpoint.png") no-repeat left;
}

#markerfine {
  background: #eee url("../images/icons/sharpiefine.png") no-repeat left;
}

#marker {
  background: #eee url("../images/icons/sharpie.png") no-repeat left;
}

#marker10 {
  background: #eee url("../images/icons/marker10.png") no-repeat left;
}

#marker20 {
  background: #eee url("../images/icons/marker20.png") no-repeat left;
}

#marker40 {
  background: #eee url("../images/icons/marker40.png") no-repeat left;
}

#marker60 {
  background: #eee url("../images/icons/marker60.png") no-repeat left;
}

#marker70 {
  background: #eee url("../images/icons/marker70.png") no-repeat left;
}

#marker80 {
  background: #eee url("../images/icons/marker80.png") no-repeat left;
}

#marker100 {
  background: #eee url("../images/icons/marker100.png") no-repeat left;
}

.options {
  position: fixed;
  top: 8em;
  right: 1.8em;
  width: 50px;
  z-index: 2;
}

.options li {
  display: block;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: var(--box-shadow);
}

.options li.active {
  width: 55px;
  border-left: #4993bc 5px solid;
}

.options li:hover {
  opacity: 0.8;
  transition-duration: var(--transition-duration);
}

.options li:first-child {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.options li:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

#grid-button {
  background-image: url("../images/icons/grid.svg");
  background-color: #fff;
}

#onePt-button {
  background-image: url("../images/icons/1pt.svg");
  background-color: #fff;
}

#twoPt-button {
  background-image: url("../images/icons/2pt.svg");
  background-color: #fff;
}

#save {
  background-color: var(--primary-color);
  background-image: url("../images/icons/saveicon.svg");
}

#gridToggle {
  background-color: var(--primary-color);
  background-image: url("../images/icons/grid.svg");
}

#layers {
  background-color: var(--primary-color);
  background-image: url("../images/icons/layers.svg");
}

#togglegrid a {
  float: left;
  height: 40px;
  width: 40px;
  opacity: 0.6;
  transition: 0.5s;
}

#togglegrid a:hover {
  opacity: 1;
  transition: 0.5s;
}

#download a {
  float: left;
  height: 40px;
  width: 40px;
  opacity: 0.6;
  transition: 0.5s;
}

#download a:hover {
  opacity: 1;
  transition: 0.5s;
}

#clear {
  float: left;
  height: 40px;
  width: 40px;
  opacity: 0.9;
  transition: 0.5s;
}

#clear:hover {
  opacity: 1;
  transition: 0.5s;
}

.undo {
  background-color: #e05858;
  background-image: url("../images/icons/undo.svg");
  cursor: pointer;
}

.erase-button {
  right: 260;
  top: 80;
  position: absolute;
  background-color: red;
  /*background-image: url('../images/icons/undo.svg');*/
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-size: 50px 50px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

/* Lesson Overlay */
#lessonOverlay {
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-color);
  z-index: -1;
  position: fixed;
  opacity: 0;
  top: 0px;
  left: 0px;
  transition-duration: var(--transition-duration);
  overflow-y: scroll;
}

#lessonOverlay.active {
  /* opacity: 0.95; */
  opacity: 1;
  z-index: 3;
  transition-duration: var(--transition-duration);
}

.overlayContent {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 100px auto;
}

#overlayMain {
  width: 100%;
  min-height: 380px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 18px;
}

#overlayResults {
  width: 100%;
  min-height: 380px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 18px;
  display: none;
}

.overlayTitle {
  float: left;
  font-size: 2.2em;
  font-weight: 100;
  line-height: 60px;
  height: 60px;
  padding-left: 20px;
}

#leoLesson {
  margin-top: 40px;
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url("../images/avatars/leo.svg");
  background-repeat: no-repeat;
  border-radius: 150px;
  display: inline-block;
}

.leoName {
  background-color: #0c2330;
  height: 2em;
  line-height: 2em;
  border-radius: 1em;
  width: 6em;
  font-size: 1.4em;
  font-weight: 100;
  margin: 180px auto 0 auto;
  box-shadow: var(--box-shadow);
}

#leoDialogue,
#leoFeedback {
  margin: 40px 0 0 0;
  width: 90%;
  min-height: 200px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: inline-block;
  padding: 0px 20px 20px 20px;
  font-weight: 300;
  font-size: 1.1em;
  text-align: left;
}

.leoSpeechBubble {
  height: 50px;
  width: 100px;
  background: url("../images/icons/leoSpeechBubble.svg") no-repeat;
  position: relative;
  left: -79px;
  top: -250px;
}

#prevProTip {
  height: 50px;
  width: 50px;
  background: var(--primary-color) url("../images/icons/prev.svg") no-repeat;
  border-radius: 25px;
  display: inline-block;
}

#nextProTip {
  height: 50px;
  width: 50px;
  background: var(--primary-color) url("../images/icons/next.svg") no-repeat;
  border-radius: 25px;
  display: inline-block;
}

#prevProTip:hover,
#nextProTip:hover {
  opacity: 0.8;
  transition-duration: var(--transition-duration);
}

#proTip {
  margin-top: 30px;
}

.lessonNav {
  height: 80px;
  width: 100%;
  padding-top: 10px;
}

.lessonButton {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  text-align: center;
  opacity: 0.9;
  font-size: 1.6em;
  font-weight: 100;
  cursor: pointer;
}

.lessonButton.start {
  background-color: var(--go-color);
  border-radius: 30px;
}

.lessonButton.prev {
  background: url("../images/icons/prev.svg") no-repeat left;
  background-position-x: 10%;
}

.lessonButton.next {
  background: url("../images/icons/next.svg") no-repeat right;
  background-position-x: 90%;
}

.lessonButton:hover {
  opacity: 0.6;
  transition-duration: var(--transition-duration);
}

.go-button {
  transition-duration: var(--transition-duration);
  height: 45px;
  color: #fff;
  font-size: 18px;
  box-shadow: var(--box-shadow);
  background-color: #42dcb6;
  border-radius: 30px;
  cursor: pointer;
  line-height: 45px;
  text-align: center;
}

.go-button:hover {
  background-color: #2eb997;
  transition-duration: var(--transition-duration);
  cursor: grab;
}

.cancel-button {
  background-color: #aa0000;
  transition-duration: var(--transition-duration);
  height: 45px;
  color: #fff;
  font-size: 18px;
  box-shadow: var(--box-shadow);
  border-radius: 30px;
  cursor: pointer;
  line-height: 45px;
  text-align: center;
}

.inactive-button {
  background-color: #dddddd;
  transition-duration: var(--transition-duration);
  height: 45px;
  color: #fff;
  font-size: 18px;
  box-shadow: var(--box-shadow);
  border-radius: 30px;
  cursor: pointer;
  line-height: 45px;
  text-align: center;
}

.back-button {
  background: url("../images/icons/back.svg") no-repeat left;
}

.next-button {
  background: url("../images/icons/next.svg") no-repeat right;
}

#stars {
  background: url("../images/icons/2star.svg") no-repeat center;
  width: 100%;
  height: 150px;
}

#stars.star0 {
  background: url("../images/icons/0star.svg") no-repeat center;
}
#stars.star1 {
  background: url("../images/icons/1star.svg") no-repeat center;
}
#stars.star2 {
  background: url("../images/icons/2star.svg") no-repeat center;
}
#stars.star3 {
  background: url("../images/icons/3star.svg") no-repeat center;
}
#stars.star4 {
  background: url("../images/icons/4star.svg") no-repeat center;
}
#stars.star5 {
  background: url("../images/icons/5star.svg") no-repeat center;
}

#best-stars {
  position: inherit;
  float: right;
  line-height: 60px;
  height: 60px;
  width: 320px;
  margin-right: 15px;
  top: 0;
  right: 0;
  opacity: 1;
  font-size: 1.4em;
  font-weight: 100;
  background: url("../images/icons/2star.svg") no-repeat center right;
}

#best-stars.star0 {
  background: url("../images/icons/0star.svg") no-repeat center right;
  background-size: 80% 80%;
}
#best-stars.star1 {
  background: url("../images/icons/1star.svg") no-repeat center right;
  background-size: 80% 80%;
}
#best-stars.star2 {
  background: url("../images/icons/2star.svg") no-repeat center right;
  background-size: 80% 80%;
}
#best-stars.star3 {
  background: url("../images/icons/3star.svg") no-repeat center right;
  background-size: 80% 80%;
}
#best-stars.star4 {
  background: url("../images/icons/4star.svg") no-repeat center right;
  background-size: 80% 80%;
}
#best-stars.star5 {
  background: url("../images/icons/5star.svg") no-repeat center right;
  background-size: 80% 80%;
}

#overlay-overview-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#overlay-overview-content li {
  height: 60px;
  line-height: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  padding-left: 20px;
}

#overlay-overview-content li:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition-duration: var(--transition-duration);
}

.lesson-overview-right {
  width: 600px;
  float: right;
  background-color: rgba(0, 0, 0, 0.2);
}

.lesson-overview-left {
  width: 400px;
  float: left;
  background-color: rgba(0, 0, 0, 0.1);
}

.lesson-overview-summary {
  margin-top: 70px;
  height: 83px;
  padding: 0 20px 0 20px;
}

#performance {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.stat-container {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.2);
  text-align: left;
  margin-bottom: 20px;
  font-weight: 100;
}

#precision-total {
  width: 0px;
  height: 50px;
  background: #42dcb6 url("../images/icons/statPrecision.svg") left center
    no-repeat;
  line-height: 50px;
  text-align: left;
  padding-right: 20px;
  padding-left: 50px;
  border-radius: 25px;
  transition-duration: 1s;
}

.stat-value {
  height: 50px;
  line-height: 50px;
  float: right;
  position: relative;
  right: 10px;
  bottom: 50px;
  font-weight: 400;
}

#smoothness-total {
  width: 0px;
  height: 50px;
  background: #4993bc url("../images/icons/statFinesse.svg") left center
    no-repeat;
  line-height: 50px;
  text-align: left;
  padding-right: 20px;
  padding-left: 50px;
  border-radius: 25px;
  transition-duration: 1s;
}

#speed-total {
  width: 0px;
  height: 50px;
  background: #c63938 url("../images/icons/statSpeed.svg") left center no-repeat;
  line-height: 50px;
  text-align: left;
  padding-right: 20px;
  padding-left: 50px;
  border-radius: 25px;
  transition-duration: 1s;
}

#pressure-total {
  width: 0px;
  height: 40px;
  background-color: #6838c6;
  line-height: 40px;
  text-align: left;
  padding-left: 10px;
  border-radius: 20px;
  transition-duration: 1s;
}

#warmup {
  position: fixed;
  background: var(--primary-color) url("../images/icons/warmup.svg") no-repeat
    left center;
  bottom: 1.5em;
  right: 1.5em;
  height: 50px;
  line-height: 50px;
  font-size: 1.1em;
  width: 140px;
  border-radius: 25px;
  padding: 0 0 0 50px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

#warmup:hover {
  opacity: 0.7;
  transition-duration: var(--transition-duration);
}

input,
input[type="email"] {
  color: #eee;
}

#red {
  background-color: rgb(177, 41, 41);
}

#orange {
  background-color: rgb(212, 147, 24);
}

#yellow {
  background-color: rgb(212, 186, 38);
}

#green {
  background-color: rgb(35, 158, 66);
}

#blue {
  background-color: rgb(56, 54, 184);
}

#violet {
  background-color: rgb(90, 31, 156);
}

#white {
  background-color: white;
}

#sketchPlay {
  position: fixed;
  left: 10%;
  bottom: 28px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--primary-color);
  background-image: url("../images/icons/play.svg");
  box-shadow: var(--box-shadow);
}

#sketchPlay:hover {
  opacity: 0.8;
  transition-duration: var(--transition-duration);
}

#sketchPlay.paused {
  background-image: url("../images/icons/pause.svg");
}

#sketchToggle {
  position: fixed;
  bottom: 20px;
  left: 15%;
  height: 40px;
  width: 75%;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: var(--primary-color);
  width: 30px;
  height: 30px;
  margin-top: -15px;
  box-shadow: var(--box-shadow);
}
input[type="range"]::-moz-range-thumb {
  background-color: var(--primary-color);
  width: 30px;
  height: 30px;
}
input[type="range"]::-ms-thumb {
  background-color: var(--primary-color);
  width: 30px;
  height: 30px;
}

/***** These are to edit the thumb and the text inside the thumb *****/
input[type="range"] + .thumb {
  background-color: var(--primary-color);
  margin-left: 0px;
  margin-top: -15px;
  display: none;
}
input[type="range"] + .thumb.active .value {
  color: #fff;
}

/* Sketchbook */

.sketch {
  background-color: #fff;
  margin: 0 15px 15px 0;
  border-radius: var(--master-border-radius);
  background-size: contain;
  background-repeat: no-repeat;
  height: auto;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.sketch:hover {
  box-shadow: var(--box-shadow-hover);
  transition-duration: var(--transition-duration);
}

.sketch img {
  width: 100%;
  border-radius: var(--master-border-radius);
}

#train {
  background-image: url("../images/sketches/SpaceFreightTrain.jpg");
}

#cusco {
  background-image: url("../images/sketches/CuscoCathedralHighRes.png");
}

#spider1 {
  background-image: url("../images/sketches/ClassIII.jpg");
}

#spider2 {
  background-image: url("../images/sketches/ClassIV.jpg");
}

#airship {
  background-image: url("../images/sketches/airship.png");
}

#at {
  background-image: url("../images/sketches/AT-ZT.jpg");
}

.button {
  background-color: var(--primary-color);
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  min-width: 100px;
  padding: 0 20px;
  font-size: 1.1em;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.newSketch {
  position: fixed;
  background: var(--primary-color) url(../images/icons/warmup.svg) no-repeat
    left center;
  bottom: 1.5em;
  right: 1.5em;
  width: 160px;
  padding: 0 0 0 50px;
}

/* Challenges  */

.challenge {
  margin: 0 15px 15px 0;
  background-size: contain;
  border-radius: var(--master-border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.challenge:hover {
  box-shadow: var(--box-shadow-hover);
  transition-duration: var(--transition-duration);
}

.challengeImage {
  background-color: #fff;
  background-size: cover;
  height: 250px;
  overflow: hidden;
  border-top-left-radius: var(--master-border-radius);
  border-top-right-radius: var(--master-border-radius);
}

.lockedChallenge {
  background-color: var(--secondary-color);
  background-image: url("../images/icons/locked.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.8;
  overflow: hidden;
  height: 100%;
  z-index: 2;
  border-top-left-radius: var(--master-border-radius);
  border-top-right-radius: var(--master-border-radius);
}

.challengeTitle {
  background-color: var(--primary-color);
  background-image: url("../images/icons/trophy-inactive.svg");
  background-repeat: no-repeat;
  background-position: left center;
  height: 2em;
  line-height: 2em;
  font-size: 1.8em;
  font-weight: 200;
  padding: 0 20px 0 55px;
  border-bottom-left-radius: var(--master-border-radius);
  border-bottom-right-radius: var(--master-border-radius);
}

.challengeTitle.completed {
  background-image: url("../images/icons/trophy.svg");
}

/* MEMBERSHIP */
#subscriptionOverlay {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  opacity: 0;
  position: fixed;
  z-index: -1;
  transition-duration: var(--transition-duration);
  overflow-y: hidden;
}

#subscriptionOverlay.active {
  opacity: 0.95;
  overflow-y: auto;
  z-index: 5;
  transition-duration: var(--transition-duration);
}

#exitSubscription {
  height: 5em;
  width: 5em;
  background-image: url("../images/icons/exit.svg");
  top: 1.2em;
  right: 1.3em;
  position: fixed;
  cursor: pointer;
}

.membershipCard {
  margin: 0 15px 15px 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--master-border-radius);
}

.membershipCard ul {
  list-style-type: none;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.membershipCard li {
  padding: 0 40px;
  line-height: 40px;
  font-size: 1.1em;
  background-image: url("../images/icons/skill.png");
  background-position: 12px 12px;
  background-repeat: no-repeat;
}

/* Arcade */
.arcade {
  border-radius: var(--master-border-radius) !important;
}

.arcade img {
  border-radius: var(--master-border-radius) !important;
}

.sandalaTitle {
  position: fixed;
  top: 1.5em;
  left: 6em;
  width: 20em;
  height: 4em;
  background-image: url("../images/arcade/sandala/sandalaTitle.png");
  background-size: 100% 100%;
}

/* Lesson Review Copy-Paste */

#review-border {
  outline: #357ca3 solid 5px;
  width: 68%;
  height: 90%;
  position: absolute;
  top: 70px;
  right: 12px;
  opacity: 0;
}

.lessoncontainer {
  overflow: hidden;
}

.lessonfullcontainer {
  overflow: hidden;
  margin: 60 0 0 0;
}

.leftpane {
  top: 0;
  width: 30%;
  height: 100%;
  position: absolute;
  background-color: white;
}

.lessonsidecontainer {
  float: left;
  clear: left;
  height: 88%;
  top: 12%;
  left: 0;
  position: absolute;
  border-top: 1px solid #ddd;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.row.review {
  margin: 0 0 0 0;
  height: 22vh;
  padding: 0 0 0 0;
}

.exerciseReview {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;

  background: #fff;
  z-index: 100;
  /* pointer-events: none; */
  height: 100%;
  width: 50%;
}

.exerciseReview:hover {
  opacity: 0.8;
  transition-duration: 1s;
  background-color: #eee;
}